import React from 'react';
import { connect } from 'react-redux';

const styles = {
  
  settingsLabel: {
    display: "inline-block",
    textAlign: "left",
    lineHeight: "100%",
    width: "50%",
    fontSize: "5vh",
    color: "#FFF"
  },
  settingsInput: {
    width: "40%",
  },
}

const SettingsInput = ({
  id,
  setting,
  input,
  defaultValue,
}) => {
  return (
    <div>
      <label style={styles.settingsLabel}>{setting}</label>
      <input
        id={id}
        style={styles.settingsInput}
        type={input}
        autoComplete="off"
        maxLength="25"
        defaultValue={defaultValue} />
    </div>
  )
};
const mapStateToProps = (state, props) => {
  return {
    id: props.id,
    setting: props.setting,
    input: props.input,
    defaultValue: props.defaultValue,
  };
}
export default connect(mapStateToProps)(SettingsInput);
