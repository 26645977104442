import React from 'react';
import { connect } from 'react-redux';

const styles = {
  race: {
    position: "absolute",
    width: "60%",
    height: "10%",
    bottom: "10%",
    left: 0,
    right: 0,
    margin: "auto",
    fontSize: "5vh",
    textAlign: "center",
    backgroundColor: "#202020",
    borderRadius: "2vh",
  },
  text: {
    lineHeight: "200%",
    textAlign: "center",
    height: "100%",
  }
};

const Race = ({race}) => {
  return (
    <div style={styles.race}><p style={styles.text}>RACE TO {race}</p></div>
  )
};
const mapStateToProps = (state, props) => {
  return {
    race: props.race
  };
}
export default connect(mapStateToProps)(Race);
