import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    common: {
      race: 'RACE TO',
    },
    menu: {
      home: 'HOME',
      singles: '1v1 RACE',
      scotch: 'SCOTCH DOUBLES',
      practice: 'PRACTICE',
      settings: 'SETTINGS',
      stopStream: 'STOP STREAM',
      endGame: 'END GAME',
    },
    playerCard: {
      scanPlayer: 'Scan Player Card',
      unranked: 'UNRANKED',
      clearPlayer: 'CLEAR PLAYER',
    },
    gameDataForm: {
      player1: 'PLAYER 1',
      player2: 'PLAYER 2',
      race: 'RACE TO',
    },
    homeScreen: {
      singles: '1V1 RACE',
      scotch: 'SCOTCH DOUBLES',
      practice: 'PRACTICE',
      settings: 'SETTINGS',
    },
    practiceScreen: {
      title: 'Practice Mode',
      startRecording: 'Start Recording',
      recording: 'RECORDING',
      stopRecording: 'Stop Recording',
    }
  }
})
export default strings;
