import thunk from "redux-thunk";

import { createStore, combineReducers, applyMiddleware } from "redux";
import gameReducer from "../reducers/game";
import playersReducer from "../reducers/players";
import tableReducer from "../reducers/table";
import descriptionReducer from "../reducers/history";


const logger = store => next => action => {
  console.group(action.type);
  console.info('dispatching', action)
  let result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
}

export default () => {
  const store = createStore(
    combineReducers({
      game: gameReducer,
      players: playersReducer,
      table: tableReducer,
      description: descriptionReducer,
    }),
    applyMiddleware(logger, thunk)
  );

  return store;
};
