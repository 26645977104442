import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpVFrQFD9bF9asUMfoqPTDbd33seUs4mM",
  authDomain: "elosports.firebaseapp.com",
  databaseURL: "https://elosports.firebaseio.com",
  projectId: "elosports",
  storageBucket: "elosports.appspot.com",
  messagingSenderId: "793217318287",
  appId: "1:793217318287:web:2335fc8ea3307b1f789ffa",
};

export default firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore;
export const storage = firebase.storage;
export const database = firebase.database;
console.log(database);
export const fieldValue = firebase.firestore.FieldValue;