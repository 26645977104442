import { fieldValue, firestore } from "../../firebase/firebase";
import "regenerator-runtime/runtime";
import { setPlayer1Score, setPlayer2Score } from "./players";

export const watchTable = () => (dispatch, getState) => {
  const { table, game } = getState();
  
  return firestore()
    .collection("tables")
    .doc(`${table.venue}_${table.id}`)
    .onSnapshot(
      {
        includeMetadataChanges: true,
      },
      async (doc) => {
        if (!doc.exists) {
          console.log("Error: No table found in database...");
          return;
        }

        // If we see that there is a new match, we need to follow that match data
        const data = doc.data();

        if (data.active && data.matchId) {
          const matchDoc = await firestore()
            .collection("matches")
            .doc(data.matchId)
            .get();
          const matchData = matchDoc.data();
          
          const player1Promise = firestore()
            .collection("users")
            .doc(matchData.host)
            .get();
          const player2Promise = firestore()
            .collection("users")
            .doc(matchData.guest)
            .get();
          const [player1Doc, player2Doc] = await Promise.all([player1Promise, player2Promise]);
          if (player1Doc.exists) {
            const player1Data = player1Doc.data();
            dispatch(setPlayer1({
              id: matchData.host,
              name: player1Data.nickname
                ? player1Data.nickname
                : player1Data.displayName,
              elo: Math.floor(player1Data.elo ? player1Data.elo : "000"),
              profilePicUrl: player1Data.profilePicUrl,
              race: matchData.hostRace,
            }));
          } else {
            dispatch(setPlayer1({
              name: matchData.host,
              race: matchData.hostRace,
            }));
          }

          if (player2Doc.exists) {
            const player2Data = player2Doc.data();
            dispatch(setPlayer2({
              id: data.guest,
              name: player2Data.nickname
                ? player2Data.nickname
                : player2Data.displayName,
              elo: Math.floor(player2Data.elo ? player2Data.elo : "000"),
              profilePicUrl: player2Data.profilePicUrl,
              race: matchData.guestRace,
            }));
          } else {
            dispatch(setPlayer2({
              name: matchData.guest,
              race: matchData.guestRace,
            }));
          }

          dispatch({
            type: "START_MATCH",
            id: data.matchId,
            isStreaming: data.isStreaming,
          })
        }

        if (!!data.matchId && data.matchId != game.id) {
          dispatch(setMatchId(data.matchId));
        }
      }
    );
}

export const watchMatch = () => (dispatch, getState) => {
  const matchId = getState().game.id;
  if (!matchId) {
    return () => {};
  }

  return firestore()
    .collection("matches")
    .doc(matchId)
    .onSnapshot({ includeMetadataChanges: true }, async (doc) => {
      if (doc.exists && !doc.metadata.hasPendingWrites) {
        const data = doc.data();
        const {player1, player2} = getState().players;

        if (data.host && data.host != player1.id) {
          firestore()
            .collection("users")
            .doc(data.host)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const player1Data = doc.data();
                dispatch(
                  setPlayer1({
                    id: data.host,
                    name: player1Data.nickname
                      ? player1Data.nickname
                      : player1Data.displayName,
                    elo: Math.floor(player1Data.elo ? player1Data.elo : "000"),
                    profilePicUrl: player1Data.profilePicUrl,
                  })
                );
              }
            });
        }

        if (data.guest && data.guest != player2.id) {
          firestore()
            .collection("users")
            .doc(data.guest)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const player2Data = doc.data();
                dispatch(
                  setPlayer2({
                    id: data.guest,
                    name: player2Data.nickname
                      ? player2Data.nickname
                      : player2Data.displayName,
                    elo: Math.floor(player2Data.elo ? player2Data.elo : "000"),
                    profilePicUrl: player2Data.profilePicUrl,
                  })
                );
              }
            });
        }
      }
    });
};

export const watchActiveMatch = () => (dispatch, getState) => {
  const { game } = getState();
  return firestore()
    .collection("matches")
    .doc(game.id)
    .onSnapshot({includeMetadataChanges: true}, (doc) => {
      const { player1, player2 } = getState().players;
      if (doc.exists) {
        const data = doc.data();
        if (data.hostScore != player1.score) {
          dispatch(setPlayer1Score(data.hostScore));
        }
        if (data.guestScore != player2.score) {
          dispatch(setPlayer2Score(data.guestScore));
        }

        if (data.hostScore == data.hostRace) {
          dispatch(setWinner("p1"));
        } else if (data.guestScore == data.guestRace) {
          dispatch(setWinner("p2"));
        } else {
          dispatch(setWinner(undefined));
        }

        if (!!data.streamLink) {
          dispatch(setStreamLink(data.streamLink))
        } else {
          dispatch(setStreamLink(undefined));
        }

        if (data.matchEndTime) {
          dispatch(resetGame());
        }
      }
    });
}

export const startMatch =
  (p1Name, p2Name, isStreaming, streamDescription = "") =>
  async (dispatch, getState) => {
    const matchId = getState().game.id;
    const room = getState().table.venue + "_" + getState().table.id;
    const { player1, player2 } = getState().players;

    if (matchId) {
      console.log("if match id...");
      firestore()
        .collection("matches")
        .doc(matchId)
        .update({
          host: player1.id ? player1.id : p1Name == "" ? "PLAYER ONE" : p1Name,
          hostScore: 0,
          hostRace: player1.race,
          guest: player2.id ? player2.id : p2Name == "" ? "PLAYER TWO" : p2Name,
          guestScore: 0,
          guestRace: player2.race,
          description: streamDescription,
          tableId: room,
          matchStartTime: firestore.FieldValue.serverTimestamp(),
        });

      firestore().collection("tables").doc(room).update({
        active: true,
        isStreaming,
      });

      firestore().collection("tables").doc("autogenerator").update({
        tablesToStartStream: fieldValue.arrayUnion(room),
      })

      // wait for 1 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));

      // function: table linking mode for teams event
      // if table doc contains a property called linkedTable, then assign the same match to the linked table
      firestore().collection("tables").doc(room).get().then((doc) => {
        const data = doc.data();
        if (data.linkedTable) {
          firestore().collection("tables").doc(data.linkedTable).update({
            matchId: match.id,
            active: true,
            isStreaming,
          })
          firestore().collection("tables").doc("autogenerator").update({
            tablesToStartStream: fieldValue.arrayUnion(data.linkedTable),
          })
        }
      })

      dispatch(setPlayer1({
        name: player1.id ? player1.name : p1Name == "" ? "PLAYER ONE" : p1Name,
      }));
      dispatch(setPlayer2({
        name: player2.id ? player2.name : p2Name == "" ? "PLAYER TWO" : p2Name,
      }))

      dispatch({
        type: "START_MATCH",
        id: matchId,
        isStreaming,
      })
    } else if (isStreaming) {
      console.log("else if is streaming...");
      // If we want to stream but there is no active match (neither player is a member)
      // we will still need to create a new match in the system. Lame...
      const match = await firestore()
        .collection("matches")
        .add({
          host: p1Name == "" ? "PLAYER ONE" : p1Name,
          hostScore: 0,
          hostRace: player1.race,
          guest: p2Name == "" ? "PLAYER TWO" : p2Name,
          guestScore: 0,
          guestRace: player2.race,
          description: streamDescription,
          tableId: room,
          matchStartTime: firestore.FieldValue.serverTimestamp(),
        });

      // update this table
      firestore().collection("tables").doc(room).update({
        matchId: match.id,
        active: true,
        isStreaming,
      });

      firestore().collection("tables").doc("autogenerator").update({
        tablesToStartStream: fieldValue.arrayUnion(room),
      })
      // wait for 1 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));

      // function: table linking mode for teams event
      // if table doc contains a property called linkedTable, then assign the same match to the linked table
      firestore().collection("tables").doc(room).get().then((doc) => {
        const data = doc.data();
        if (data.linkedTable) {
          firestore().collection("tables").doc(data.linkedTable).update({
            matchId: match.id,
            active: true,
            isStreaming,
          })
          firestore().collection("tables").doc("autogenerator").update({
            tablesToStartStream: fieldValue.arrayUnion(data.linkedTable),
          })
        }
      })

      dispatch(setPlayer1({
        name: p1Name == "" ? "PLAYER ONE" : p1Name,
      }))
      dispatch(setPlayer2({
        name: p2Name == "" ? "PLAYER TWO" : p2Name,
      }))

      dispatch({
        type: "START_MATCH",
        id: match.id,
        isStreaming,
      })
    } else {
      dispatch(setPlayer1({
        name: p1Name == "" ? "PLAYER ONE" : p1Name,
      }))
      dispatch(setPlayer2({
        name: p2Name == "" ? "PLAYER TWO" : p2Name
      }))

      dispatch({
        type: "START_MATCH",
        isStreaming,
      })
    }
  };

export const endMatch = () => (dispatch, getState) => {
  const {game, players, table} = getState();
  const room = table.venue + "_" + table.id;

  if (!!game.id) {
    let winner = null;
    if (game.winner == "p1") {
      winner = players.player1.id ? players.player1.id : players.player1.name;
    } else if (game.winner == "p2") {
      winner = players.player2.id ? players.player2.id : players.player2.name;
    }
    firestore().collection("matches").doc(game.id).update({
      winner,
      matchEndTime: firestore.FieldValue.serverTimestamp()
    })
  }

  // function: table linking mode for teams event
  // if table doc contains a property called linkedTable, then assign the same match to the linked table
  firestore().collection("tables").doc(room).get().then((doc) => {
    const data = doc.data();


    // stop this table
    firestore().collection("tables").doc(room).update({
      active: false,
      recordingMode: 'stopped',
      isStreaming: false,
      matchId: null
    })
    firestore().collection("tables").doc("autogenerator").update({
      tablesToStopStream: fieldValue.arrayUnion(room),
      heartbeatTimestamp: firestore.FieldValue.serverTimestamp(),
    })

    // wait for 1 second
    setTimeout(() => {
      if (data.linkedTable) {
        firestore().collection("tables").doc(data.linkedTable).update({
          active: false,
          recordingMode: 'stopped',
          isStreaming: false,
          matchId: null
        })

        firestore().collection("tables").doc("autogenerator").update({
          tablesToStopStream: fieldValue.arrayUnion(data.linkedTable),
          heartbeatTimestamp: firestore.FieldValue.serverTimestamp(),
        })
      }
    }, 5000);

  })

  dispatch(resetGame());
}

export const setPlayer1 = (player) => ({
  type: "SET_PLAYER_1",
  player,
});

export const setPlayer2 = (player) => ({
  type: "SET_PLAYER_2",
  player,
});

export const clearPlayer1 = () => (dispatch, getState) => {
  const matchId = getState().game.id;

  firestore().collection("matches").doc(matchId).update({
    host: null,
  });
  dispatch(
    setPlayer1({
      id: undefined,
      name: undefined,
      nickName: undefined,
    })
  );
};

export const clearPlayer2 = () => (dispatch, getState) => {
  const matchId = getState().game.id;

  firestore().collection("matches").doc(matchId).update({
    guest: null,
  });
  dispatch(
    setPlayer2({
      id: undefined,
      name: undefined,
      nickName: undefined,
    })
  );
};

export const incrementPlayer1Race = (increment) => (dispatch, getState) => {
  const race = getState().players.player1.race + increment;
  dispatch(setPlayer1Race(race));
  return () => {};
};

export const setPlayer1Race = (race) => {
  return {
    type: "SET_PLAYER_1_RACE",
    race: race >= 1 ? race : 1,
  };
};

export const incrementPlayer2Race = (increment) => (dispatch, getState) => {
  const race = getState().players.player2.race + increment;
  dispatch(setPlayer2Race(race));
  return () => {};
};

export const setPlayer2Race = (race) => {
  return {
    type: "SET_PLAYER_2_RACE",
    race: race >= 1 ? race : 1,
  };
};

export const setMatchId = (id) => ({
  type: "SET_MATCH_ID",
  id,
});

export const resetGame = () => ({
  type: "RESET_GAME",
});

export const setIsStreaming = (isStreaming) => ({
  type: "SET_IS_STREAMING",
  isStreaming,
});

export const setWinner = (winner) => ({
  type: "SET_WINNER",
  winner,
});

export const setStreamLink = (link) => ({
  type: "SET_STREAM_LINK",
  link,
})
