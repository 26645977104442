import React, { useState } from 'react';
import { connect } from 'react-redux';
import { stack as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom';

import { endMatch, setIsStreaming } from '../../redux/actions/game';

import { firestore } from '../../firebase/firebase';

import strings from '../../config/strings';
const { menu: menuStrings } = strings;

const MatchMenu = ({game, table, dispatch}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const tableDocRef = firestore().collection("tables").doc(`${table.venue}_${table.id}`);
  const matchDocRef = firestore().collection("matches").doc(`${game.id}`);


  const handleMenuStateChange = state => {
    setMenuOpen(state.isOpen);
  }

  const stopStream = () => {
    if (!!game.isStreaming) {
      if (!!game.id) {
        tableDocRef.update({
          isStreaming: false,
        })
      }
      dispatch(setIsStreaming(false));
    }

    setMenuOpen(false);
  }

  const endGame = () => {
    dispatch(endMatch());
  }

  return (
    <Menu
      isOpen={menuOpen}
      onStateChange={state => handleMenuStateChange(state)}>
        {!!game.isStreaming && <a onClick={() => stopStream()}>{menuStrings.stopStream}</a>}
        <Link to="/" onClick={() => endGame()}>{menuStrings.endGame}</Link>
    </Menu>
  )
};
const mapStateToProps = (state) => {
  return {
    game: state.game,
    table: state.table,
  };
}
export default connect(mapStateToProps)(MatchMenu);
