import React from 'react';
import { connect } from 'react-redux';

import { incrementPlayer1Score, incrementPlayer2Score } from '../redux/actions/players';

const ScoreButton = ({ side, operation, dispatch }) => {
  let [triggered, setTriggered] = React.useState(0);
  const changePlayerScore = () => {
    if (triggered === 0) {
      if (side === "p1") {
        dispatch(incrementPlayer1Score(operation));
      } else {
        dispatch(incrementPlayer2Score(operation));
      }
    }
  };

  const handleClick = (e) => {
    changePlayerScore();
  };

  const handleTouchEnd = (e) => {
    changePlayerScore();
    e.preventDefault();
  };

  return (
    <button
      type="button"
      className={`${side} ${operation} scorebutton`}
      onClick={handleClick}
      onTouchEnd={handleTouchEnd}
      ></button>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { side, operation } = ownProps;
  let player;
  if ( side === "p1" ) {
    player = state.players.player1;
  } else {
    player = state.players.player2;
  }

  return {
    game: state.game,
    players: state.players,
    table: state.table,
    side,
    operation
  };
}
export default connect(mapStateToProps)(ScoreButton);
