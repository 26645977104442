import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";
import { endMatch } from "../redux/actions/game";

const InactivityModal = ({ timeout }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [inactiveTime, setInactiveTime] = useState(COUNTDOWN);
  const INACTIVITY_TIME = (timeout - 5) * 60 * 1000;
  const COUNTDOWN = 5 * 60;
  let inactivityTimer = null;
  let countdownInterval = null;

  useEffect(() => {
    console.log("INACTIVITY MODAL ONNNNNN");
    const resetTimer = () => {
        if (inactivityTimer) clearTimeout(inactivityTimer);
        if (countdownInterval) clearInterval(countdownInterval);
        console.log("TIMER RESET");
        inactivityTimer = setTimeout(() => {
            setModalOpen(true);
            startCountdown(COUNTDOWN);
        }, INACTIVITY_TIME);
    };

    const startCountdown = (seconds) => {
        let counter = seconds;
        countdownInterval = setInterval(() => {
            counter--;
            console.log(counter);
            setInactiveTime(counter);
            if (counter === 0) {
                dispatch(endMatch());
            }
        }, 1000);
    }

    const resetEvents = ['mousedown', 'keydown', 'touchstart'];

    resetEvents.forEach((event) => {
        window.addEventListener(event, resetTimer);
    });

    resetTimer();

    const closeOnOutsideClick = (event) => {
        if (modalOpen && !event.target.closest('.modal')) {
          setModalOpen(false); // Close modal if clicked outside
        }
      };
    document.addEventListener('click', closeOnOutsideClick);

    return () => {
        resetEvents.forEach((event) => {
            window.removeEventListener(event, resetTimer);
        });
        clearTimeout(inactivityTimer);
        clearInterval(countdownInterval);
        document.removeEventListener('click', closeOnOutsideClick);
    }
  }, [inactivityTimer, countdownInterval]);

  return (
    <Modal
        open={modalOpen}
        onClose={() => {
            setModalOpen(false);
            clearTimeout(inactivityTimer);
            clearInterval(countdownInterval);
        }}
        size='tiny'
    >
        <Modal.Content>
            <p>Still playing...? Tap to resume match!</p>
            <p>Ending stream in:</p>
            <p>{inactiveTime}s</p>
        </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
    timeout: state.table.timeout,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InactivityModal);
