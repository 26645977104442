import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Label, Table } from "semantic-ui-react";
import { firestore } from "../firebase/firebase";

const styles = {
  container: {
    flexGrow: 99,
    backgroundColor: "#383838",
    color: "white",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "3%",
    fontSize: "1.5em",
    height: "100%",
  },
};

const PlayerRow = ({ pos, player }) => {
  return (
    <Table.Row>
      <Table.Cell>
        {pos}
      </Table.Cell>
      <Table.Cell>
        {player.nickname
          ? player.nickname.toUpperCase()
          : player.displayName.toUpperCase()}
      </Table.Cell>
      <Table.Cell>
          {player.gameWin}/{player.gameCount} (
          {Math.round((player.gameWin / player.gameCount) * 100)}%)
      </Table.Cell>
      <Table.Cell>
          {player.setWin}/{player.setCount} (
          {Math.round((player.setWin / player.setCount) * 100)}%)
      </Table.Cell>
      <Table.Cell>
        {Math.round(player.elo)}
      </Table.Cell>
    </Table.Row>
  );
};

const Rankings = ({ venueData }) => {
  const [groupExists, setGroupExists] = useState(true);
  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    if (venueData && venueData.groups) {
      firestore()
        .collection("groups")
        .doc(venueData.groups[0])
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const data = doc.data();
            const fullRankings = [];
            if (data.rankings) {
              for (const player of data.rankings) {
                const playerDoc = await firestore()
                  .collection("users")
                  .doc(player.uid)
                  .get();
                const playerData = playerDoc.data();

                fullRankings.push({
                  ...player,
                  ...playerData,
                });
              }
              setRankings(fullRankings);
              setGroupExists(true);
            }
          }
        });
    } else {
      setGroupExists(false);
    }
  }, [venueData]);

  return (
    <div style={styles.container}>
      {groupExists && (
        <Table inverted>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>NAME</Table.HeaderCell>
              <Table.HeaderCell>RACK WINS</Table.HeaderCell>
              <Table.HeaderCell>SET WINS</Table.HeaderCell>
              <Table.HeaderCell>ELO</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rankings.map((player, index) => (
              <PlayerRow key={player.uid} pos={index + 1} player={player} />
            ))}
          </Table.Body>
        </Table>
      )}
      <h2>TAP TO BEGIN...</h2>
      <h3>WANT TO JOIN THE LEADERBOARD? DOWNLOAD OUR APP</h3>
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    venueData: props.venueData,
  };
};
export default connect(mapStateToProps)(Rankings);
