import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestore } from "../firebase/firebase";

import Header from "./Header";
import VideoPlayer from "./VideoPlayer";

const styles = {
  leaderboard: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999,
    height: "100%",
  },
};

const Screensaver = ({ venueId, tableNum }) => {
  const [venueData, setVenueData] = useState(undefined);
  const [venueUrls, setVenueUrls] = useState([]);

  useEffect(() => {
    firestore()
      .collection("venues")
      .doc(venueId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setVenueData(doc.data());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [venueId]);

  useEffect(() => {
    firestore()
      .collection("tables")
      .doc(`${venueId}_${tableNum}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const sponsors = doc.data().sponsors || [];

          const fetchSponsorUrls = async (sponsors) => {
            const sponsorUrls = [];
            // push evo and wbca links
            sponsorUrls.push("https://storage.googleapis.com/releases-evosports/evobox/media/WBCA-Sponsorship-Vid.mp4");
            sponsorUrls.push("https://storage.googleapis.com/releases-evosports/evobox/media/evo-trailer-50s.mp4");
            for (const sponsor of sponsors) {
              try {
                const sponsorDoc = await firestore()
                  .collection("sponsors")
                  .doc(sponsor)
                  .get();
                if (sponsorDoc.exists) {
                  if (sponsorDoc.data().videoSrc && sponsorDoc.data().videoSrc != "")
                  sponsorUrls.push(sponsorDoc.data().videoSrc);
                }
              } catch (err) {
                console.log(err);
              }
            }
            setVenueUrls(sponsorUrls);
          };

          fetchSponsorUrls(sponsors);
        }
      })
  }, [venueId, tableNum])

  return (
    <div className="page">
      <Header venueData={venueData} />
      <VideoPlayer
        videoUrls={venueUrls}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  venueId: state.table.venue,
  tableNum: state.table.id,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Screensaver);
