const DEFAULT_RACE = localStorage.getItem('defaultRace') ? parseInt(localStorage.getItem('defaultRace')) : 5;
const playersDefaultState = {
  player1: {
    id: undefined,
    name: undefined,
    nickName: undefined,
    score: 0,
    race: DEFAULT_RACE,
  },
  player2: {
    id: undefined,
    name: undefined,
    nickName: undefined,
    score: 0,
    race: DEFAULT_RACE,
  },
  defaultRace: DEFAULT_RACE,
};

export default (state = playersDefaultState, action) => {
  switch (action.type) {
    case "SET_PLAYER_1":
      return {
        ...state,
        player1: {
          ...state.player1,
          ...action.player,
        },
      };
    case "CLEAR_PLAYER_1":
      return {
        ...state,
        player1: {
          ...state.player1,
          id: undefined,
          name: undefined,
          nickName: undefined,
        },
      };
    case "SET_PLAYER_2":
      return {
        ...state,
        player2: {
          ...state.player2,
          ...action.player,
        },
      };
    case "CLEAR_PLAYER_2":
      return {
        ...state,
        player2: {
          ...state.player2,
          id: undefined,
          name: undefined,
          nickName: undefined,
        },
      };
    case "SET_PLAYER_1_RACE":
      return {
        ...state,
        player1: {
          ...state.player1,
          race: action.race,
        },
      };
    case "SET_PLAYER_2_RACE":
      return {
        ...state,
        player2: {
          ...state.player2,
          race: action.race,
        },
      };
    case "SET_PLAYER_1_SCORE":
      return {
        ...state,
        player1: {
          ...state.player1,
          score: action.score,
        }
      }
    case "SET_PLAYER_2_SCORE":
      return {
        ...state,
        player2: {
          ...state.player2,
          score: action.score,
        }
      }
    case "SET_SCORE":
      return {
        ...state,
        player1: {
          ...state.player1,
          score: action.p1Score,
        },
        player2: {
          ...state.player2,
          score: action.p2Score,
        },
      };
    case "SAVE_CONFIG":
      return {
        player1: {
          ...state.player1,
          race: action.defaultRace,
        },
        player2: {
          ...state.player2,
          race: action.defaultRace,
        },
        defaultRace: action.defaultRace,
      }
    case "RESET_GAME":
      const newState = {
        ...state,
        player1: {
          ...playersDefaultState.player1,
          race: state.defaultRace,
        },
        player2: {
          ...playersDefaultState.player2,
          race: state.defaultRace,
        }
      }
      return newState;
    default:
      return state;
  }
};
