export const saveDescription = (description) => {
  // save values to local storage
  localStorage.setItem('last_description', description);

  return {
    type: 'SAVE_DESCRIPTION',
    description
  }
}

