import { firestore } from "../../firebase/firebase";
import { setWinner } from "./game";

export const incrementPlayer1Score = (operation) => (dispatch, getState) => {
  const {race, score} = getState().players.player1;
  const { game } = getState();
  const increment = operation == "add" ? 1 : -1;

  const newScore = score + increment;

  if (
    newScore < 0 ||
    newScore > race ||
    (game.winner == "p2")) return;

  if (game.id) {
    firestore().collection("matches").doc(game.id).update({
      hostScore: newScore,
    })
  }

  dispatch(setWinner(newScore == race ? "p1" : undefined));
  dispatch(setPlayer1Score(newScore));
}

export const incrementPlayer2Score = (operation) => (dispatch, getState) => {
  const {race, score} = getState().players.player2;
  const { game } = getState();
  const increment = operation == "add" ? 1 : -1;

  const newScore = score + increment;

  if (
    newScore < 0 ||
    newScore > race ||
    (game.winner == "p1")) return;

  if (game.id) {
    firestore().collection("matches").doc(game.id).update({
      guestScore: newScore,
    })
  } 

  dispatch(setWinner(newScore == race ? "p2" : undefined));
  dispatch(setPlayer2Score(newScore));
}

export const setPlayer1Score = (score) => ({
  type: 'SET_PLAYER_1_SCORE',
  score,
})

export const setPlayer2Score = (score) => ({
  type: 'SET_PLAYER_2_SCORE',
  score,
})

export const changeScore = (side, newScore, winner) => ({
  type: 'CHANGE_SCORE',
  score: newScore,
  winner,
  side
});

export const setScore = (p1Score, p2Score) => ({
  type: 'SET_SCORE',
  p1Score,
  p2Score,
});