const config = require('../../config/config.json');

const gameDefaultState = {
  id: undefined,
  isStreaming: false,
  winner: undefined,
  gameOver: true,
  streamLink: undefined,
};

export default (state = gameDefaultState, action) => {
  switch (action.type) {
    case 'START_MATCH':
      return {
        ...state,
        id: action.id,
        isStreaming: action.isStreaming,
        gameOver: false
      };
    case 'SET_MATCH_ID':
      return {
        ...state,
        id: action.id,
      }
    case 'INCREMENT_RACE':
      return {
        ...state,
        race: action.race
      };
    case 'CHANGE_SCORE':
      return {
        ...state,
        winner: action.winner,
        gameOver: !!action.winner ? action.winner : false
      };
    case 'RESET_GAME':
      return gameDefaultState;
    case 'SET_WINNER':
      return {
        ...state,
        winner: action.winner,
      }
    case 'SET_GAME_OVER':
      return {
        ...state,
        gameOver: true,
      }
    case 'SET_IS_STREAMING':
      return {
        ...state,
        isStreaming: action.isStreaming,
      }
    case 'SET_STREAM_LINK':
      return {
        ...state,
        streamLink: action.link
      }
    default:
      return state;
  }
}
