export const saveConfig = (defaultRace, timeout) => {
  localStorage.setItem('defaultRace', defaultRace);
  localStorage.setItem('timeout', timeout);

  return {
    type: 'SAVE_CONFIG',
    defaultRace,
    timeout,
  }
}

export const saveVenueConfig = (venue, tableNumber) => {
  localStorage.setItem('venue', venue);
  localStorage.setItem('tableNumber', tableNumber);

  return {
    type: "SAVE_VENUE_CONFIG",
    id: tableNumber,
    venue,
  }
}
