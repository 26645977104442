import React from 'react';
import { connect } from 'react-redux';

import { endMatch, resetGame, setStreamId } from '../redux/actions/game';

import { firestore, fieldValue } from '../firebase/firebase';

const styles = {
  reset: {
    position: "absolute",
    width: "60%",
    height: "10%",
    bottom: "10%",
    left: 0,
    right: 0,
    margin: "auto",
    fontSize: "5vh",
    textAlign: "center",
    backgroundColor: "#202020",
    borderRadius: "2vh",
    color: "white",
    zIndex: 10,
  }
}


const ResetButton = ({ dispatch }) => {
  const reset = () => {
    dispatch(endMatch());
  }

  return (
    <button
      style={styles.reset}
      onClick={reset}>END STREAMING</button>
  );
}
const mapStateToProps = (state) => {
  return {
    game: state.game,
    players: state.players,
    table: state.table,
  };
}
export default connect(mapStateToProps)(ResetButton);
