const DEFAULT_TIMEOUT = localStorage.getItem('timeout') ? parseInt(localStorage.getItem('timeout')) : 480;
const tableDefaultState = {
  venue: localStorage.getItem('venue'),
  id: localStorage.getItem('tableNumber'),
  timeout: DEFAULT_TIMEOUT,
};

export default (state = tableDefaultState, action) => {
  console.log("ACTION:", action);
  switch (action.type) {
    case 'SAVE_VENUE_CONFIG':
      return {
        ...state,
        venue: action.venue,
        id: action.id,
      }
    case "SAVE_CONFIG":
      return {
        ...state,
        timeout: action.timeout,
      }
    default:
      return state;
  }
}
