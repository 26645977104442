import React from 'react';
import { connect } from 'react-redux';
import Race from './Race';
import ScoreButton from './ScoreButton';

const PlayerScore = ({game, player, side, dispatch}) => {
  return (
    <div className={`split ${side}`}>
      <div className="centeredScore">
        <p>{player.score}</p>
      </div>
      <div className="playerName">
        <p>{player.name}</p>
      </div>
      <ScoreButton
        side={side}
        operation="add"
      />
      <ScoreButton
        side={side}
        operation="minus"
      />
      {!game.winner && <Race race={player.race}/>}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { side } = ownProps;
  let player;
  if ( side === "p1" ) {
    player = state.players.player1;
  } else {
    player = state.players.player2;
  }
  return {
    game: state.game,
    player,
    side
  };
}
export default connect(mapStateToProps)(PlayerScore);
