import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestore } from "../firebase/firebase";

import Header from "./Header";
import Rankings from "./Rankings";

const styles = {
  leaderboard: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999,
    height: "100%",
  },
};

const Leaderboard = ({ venueId }) => {
  const [venueData, setVenueData] = useState(undefined);

  useEffect(() => {
    firestore()
      .collection("venues")
      .doc(venueId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setVenueData(doc.data());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [venueId]);

  return (
    <div className="page">
      <Header venueData={venueData}/>
      <Rankings venueData={venueData}/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  venueId: state.table.venue,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
