import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Icon, Divider } from 'semantic-ui-react';
import Websocket from 'react-websocket';

import QRCode from 'qrcode.react';

import SetupMenu from '../components/menu/SetupMenu';
import PlayerCard from '../components/PlayerCard';

import { startNewGame, incrementRace } from '../redux/actions/game';
import { setStreamId } from '../redux/actions/game';

import strings from '../config/strings';
const { practiceScreen: practiceScreenStrings } = strings;

const config = require('../config/config.json');

const PracticeScreen = ({ players, game, table, history, dispatch }) => {
  const [player, setPlayer] = useState(undefined);
  const [recording, setRecording] = useState(false);

  const toggleRecording = () => {
    setRecording(!recording);

    if (recording) {
      // stop recording
      RecordingManager.stopRecording(player.id, table);
    } else {
      // start recording
      RecordingManager.startRecording(player.id, table);
    }
  }

  const clearPlayer = () => {
    setPlayer(undefined);
  }

  const rfidScanned = (rfid) => {
    console.log('Player card scanned. ID: ' + rfid);

    PlayerDataManager.getPlayer(rfid).then(data => {
      if (data) {
        setPlayer(data);
      }
    });
  }

  return (
    <div>
      <div className="page">
        <QRCode value={`${table.venue.toUpperCase()}_${table.tableNumber.toUpperCase()}`} />
        <Button
          icon
          labelPosition='left'
          toggle
          active={recording}
          disabled={player == undefined}
          size='massive'
          onClick={toggleRecording}>
            <Icon name='record' />
            {recording ? practiceScreenStrings.stopRecording : practiceScreenStrings.startRecording}
        </Button>
      </div>
      <SetupMenu />
    </div>
  );
};

const mapStateToProps =  (state) => {
  return {
    game: state.game,
    players: state.players,
    table: state.table,
  };
}
export default connect(mapStateToProps)(PracticeScreen);
