import { useState, useEffect, useRef } from 'react';

const SCREENSAVER_TIMEOUT = 120000;

const useScreensaver = () => {
    const [show, setShow] = useState(false);
    const screensaverTimeout = useRef();

    useEffect(() => {
        startTimeout();

        return () => {
            clearTimeout(screensaverTimeout.current);
        }
    }, []);

    const startTimeout = () => {
        console.log("STARTING TIMEOUT");
        screensaverTimeout.current = setTimeout(() => setShow(true), SCREENSAVER_TIMEOUT);
    }

    const resetTimer = () => {
        console.log("RESETTING TIMEOUT");
        setShow(false);
        clearTimeout(screensaverTimeout.current);
        startTimeout();
    }

    return [show, resetTimer];
}
export default useScreensaver;