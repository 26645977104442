import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import strings from '../config/strings';
const { signIn: signIn } = strings;

const SignIn = ({ table, dispatch }) => {

  return (
    <div></div>
  );
};

const mapStateToProps =  (state) => {
  return {
    table: state.table,
  };
}
export default connect(mapStateToProps)(SignIn);
