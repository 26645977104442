const descriptionDefaultState = {
  description: localStorage.getItem('last_description'),
};

export default (state = descriptionDefaultState, action) => {
  switch (action.type) {
    case 'SAVE_DESCRIPTION':
      return {
        description: action.description,
      }
    default:
      return state;
  }
}

