import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import QRCode from "qrcode.react";

import ResetButton from "../components/ResetButton";
import PlayerScore from "../components/PlayerScore";
import MatchMenu from "../components/menu/MatchMenu";
import InactivityModal from "../components/InactivityModal";

import { watchActiveMatch } from "../redux/actions/game";

const config = require("../config/config.json");

const styles = {
  watchHere: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    top: "40%",
    width: "14%",
    zIndex: 98,
  },
  qrCode: {
    height: "100%",
    width: "100%",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    borderWidth: "thick",
  },
  streaming: {
    color: "#FFFFFF",
  },
  watch: {
    marginTop: "1%",
    color: "#FFFFFF",
  },
  reset: {
    position: "absolute",
    width: "60%",
    height: "10%",
    bottom: "10%",
    left: 0,
    right: 0,
    margin: "auto",
    fontSize: "5vh",
    textAlign: "center",
    backgroundColor: "#202020",
    borderRadius: "2vh",
    color: "white",
  },
};

const ScoreBoard = ({ game, table, dispatch }) => {
  const [redirect, setRedirect] = useState(false);
  const [timeout, setTimeout] = useState(false);

  useEffect(() => {
    dispatch(watchActiveMatch());
    if (table.timeout > 0) {
      setTimeout(true);
    }
  }, []);

  useEffect(() => {
    if (game.gameOver) {
      setRedirect(true);
    }
  }, [game.gameOver]);

  return (
    <div>
      <div>
        {!!redirect && <Redirect push to="/" />}
        <PlayerScore side="p1" />
        <PlayerScore side="p2" />
        <MatchMenu />
      </div>

      {game.streamLink ? (
        <div style={styles.watchHere}>
          <h3 style={styles.streaming}>NOW STREAMING..</h3>
          <QRCode style={styles.qrCode} value={game.streamLink} />
          <h3 style={styles.watch}>SCAN TO WATCH</h3>
        </div>
      ) : null}

      {game.winner && (
        <Link to="/">
          <ResetButton />
        </Link>
      )}

      {timeout && <InactivityModal />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    game: state.game,
    table: state.table,
    players: state.players,
  };
};
export default connect(mapStateToProps)(ScoreBoard);
