import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const LinkButton = ({
  to,
  text,
  onClick=undefined,
  size='massive',
  style={},
}) => {
  return (
    <Link to={to}>
      <Button
        className="linkButton"
        size='massive'
        style={style}
        onClick={onClick}>
          {text}
      </Button>
    </Link>
  )
};
const mapStateToProps = (state, props) => {
  return {
    to: props.to,
    text:props.text,
    onClick: props.onClick,
    size: props.size,
  };
}
export default connect(mapStateToProps)(LinkButton);
