import React, { useState, useEffect } from "react";

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "black",
    height: '80%', // Ensure the container takes up the full height
    position: 'relative',
    overflow: 'hidden'
  },
  video: {
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  textOverlay: {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'white',
    fontSize: '1.5em',
    fontWeight: 'bold',
    textAlign: 'center'
  }
};

const VideoPlayer = ({ videoUrls }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    const videoElement = document.querySelector('#playerVideo');

    const handleVideoEnd = () => {
      setCurrentVideoIndex((prevIndex) => {
        const newIndex = prevIndex >= videoUrls.length - 1 ? 0 : prevIndex + 1;
        return newIndex;
      });
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, [videoUrls]);

  useEffect(() => {
    const videoElement = document.querySelector('#playerVideo');
    if (videoElement) {
      videoElement.src = videoUrls[currentVideoIndex];
      videoElement.load(); // Load the new source

      videoElement.addEventListener('loadedmetadata', () => {
        try {
          videoElement.play(); // Play the new video after metadata is loaded
        } catch (err) {
          console.error(err);
        }
      }, { once: true });
    }
  }, [currentVideoIndex, videoUrls]);

  return (
    <div style={styles.container}>
      <video id="playerVideo" style={styles.video} autoPlay muted playsInline>
        <source src={videoUrls[currentVideoIndex]} />
        Your browser does not support the video tag.
      </video>
      <div style={styles.textOverlay}>
        <h2>TAP TO BEGIN</h2>
      </div>
    </div>
  );
};

export default VideoPlayer;
