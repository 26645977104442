import React, { useState } from 'react';
import { connect } from 'react-redux';
import { stack as Menu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom';

import strings from '../../config/strings';
const { menu: menuStrings } = strings;

const MatchMenu = ({game, dispatch}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation().pathname;

  const handleMenuStateChange = state => {
    setMenuOpen(state.isOpen);
  }

  return (
    <Menu
      isOpen={menuOpen}
      onStateChange={state => handleMenuStateChange(state)}>
        <Link
          to="/">
            {menuStrings.home}
        </Link>
        <Link
          to="/gameDataForm">
            {menuStrings.singles}
        </Link>
        <Link
          to="/settings">
            {menuStrings.settings}
        </Link>
    </Menu>
  )
};
const mapStateToProps = (state) => {
  return {
    game: state.game
  };
}
export default connect(mapStateToProps)(MatchMenu);
