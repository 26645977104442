import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import GameDataForm from '../pages/GameDataForm';
import SignIn from '../pages/SignIn';
import HomeScreen from '../pages/HomeScreen';
import PracticeScreen from '../pages/PracticeScreen';
import Settings from '../pages/Settings';
import ScoreBoard from '../pages/ScoreBoard';
import Leaderboard from '../components/Leaderboard';

const AppRouter = () => {
  return (
  <BrowserRouter>
    <div className="app">
      <Switch>
        <Route
          path="/"
          component={HomeScreen}
          exact={true} />
        <Route
          path="/signin"
          component={SignIn} />
        <Route
          path="/practice"
          component={PracticeScreen} />
        <Route
          path="/gamedataform"
          component={GameDataForm} />
        <Route
          path="/scoreboard"
          component={ScoreBoard} />
        <Route
          path="/settings"
          component={Settings} />
        <Route
          path="/leaderboard"
          component={Leaderboard} />
      </Switch>
    </div>
  </BrowserRouter>
)};
export default AppRouter;
