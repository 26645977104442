import React from "react";
import { connect } from "react-redux";
import { firestore } from "../firebase/firebase";
import PlayerCard from "./PlayerCard";

import QRCode from "qrcode.react";
import useWindowSize from "../hooks/useWindowSize";
import RaceIncrement from "./RaceIncrement";
import { clearPlayer1, clearPlayer2 } from "../redux/actions/game";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  playerInput: {
    width: "100%",
  },
  qr: {
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    borderWidth: 8,
    borderRadius: 4,
    marginTop: "4vh",
    marginBottom: "4vh",
  },
};

const PlayerInput = ({ table, player, type, dispatch }) => {
  const windowSize = useWindowSize();

  const clearPlayer = () => {
    if (type == "host") {
      dispatch(clearPlayer1());
    } else if (type == "guest") {
      dispatch(clearPlayer2());
    }
  };

  return (
    <div style={styles.container}>
      {player.id ? (
        <PlayerCard player={player} clearPlayer={clearPlayer} />
      ) : (
        <div style={styles.playerInput}>
          <input
            id={type == "host" ? "p1Input" : "p2Input"}
            style={{textAlign: "center"}}
            input="text"
            disabled={!!player.id}
            autoComplete="off"
            maxLength="25"
            placeholder="Enter Player Name"
          />

          <div style={styles.qrDisplay}>
            <QRCode
              style={styles.qr}
              size={windowSize.height ? windowSize.height * 0.13 : 0}
              value={`${table.venue.toUpperCase()}_${table.id.toUpperCase()}_${type.toUpperCase()}`}
            />
          </div>
        </div>
      )}
      <RaceIncrement type={type} />
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    game: state.game,
    table: state.table,
    player:
      props.type == "host" ? state.players.player1 : state.players.player2,
    type: props.type,
    matchId: state.game.id,
  };
};
export default connect(mapStateToProps)(PlayerInput);
