import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { storage } from "../firebase/firebase";

const styles = {
  container: {
    flexGrow: 1,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#292929",
    fontFamily: "Nexa Title",
    fontSize: "5em",
    paddingLeft: "5%",
    paddingTop: "1%",
    paddingBottom: "2%",
    height: "20%",
  },
  venueLogo: {
    width: "10%",
    marginRight: "2%",
  },
  name: {
    borderStyle: "none none solid none",
    borderColor: "#9D4BAD",
    textAlign: "left",
    alignItems: "center",
    flexGrow: 10,
  },
};

const Header = ({ id, data }) => {
  const [logoUrl, setLogoUrl] = useState(undefined);

  useEffect(() => {
    const logoRef = `venues/${id}/logo.png`;
    storage()
      .ref(logoRef)
      .getDownloadURL()
      .then((url) => {
        setLogoUrl(url);
      });
  }, [id]);

  return (
    <div style={styles.container}>
      <img style={styles.venueLogo} src={logoUrl} />
      <div style={styles.name}>
        <h1 style={{ textAlign: "left" }}>{data && data.name.toUpperCase()}</h1>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    id: state.table.venue,
    data: props.venueData,
  };
};
export default connect(mapStateToProps)(Header);
