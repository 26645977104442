import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Icon, Image, Item } from "semantic-ui-react";

import { storage } from "../firebase/firebase";

import strings from "../config/strings";

const styles = {
  container: {
    backgroundColor: "white",
    width: "60%",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2vh",
    borderStyle: "solid",
    borderColor: "#9D4BAD",
  },
  avatar: {
    height: "18vh",
    margin: "1vh",
    borderStyle: "solid",
    borderColor: "#000000",
  },
  label: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
};

const PlayerCard = ({ player, clearPlayer }) => {
  const [profilePic, setProfilePic] = useState();

  useEffect(() => {
    if (player.profilePicUrl) {
      setProfilePic(player.profilePicUrl);
    } else {
      storage()
        .ref()
        .child("/images/no_avatar.png")
        .getDownloadURL()
        .then((url) => {
          setProfilePic(url);
        });
    }
  }, []);

  return (
    <div style={styles.container}>
      <Image src={profilePic} style={styles.avatar} circular />
      <div style={styles.label}>
        <div style={styles.info}>
          <div style={styles.name}>{player.name}</div>
          <div>{player.elo ? player.elo : "UNRANKED"}</div>
        </div>
        <Button icon color="red" onClick={clearPlayer}>
          <Icon name="cancel" />
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    player: props.player,
    clearPlayer: props.clearPlayer,
  };
};
export default connect(mapStateToProps)(PlayerCard);
