import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import LinkButton from "../components/LinkButton";

import { firestore } from "../firebase/firebase";
import { storage } from "../firebase/firebase";
import { database } from "../firebase/firebase";

import strings from "../config/strings";
import useScreensaver from "../hooks/useScreensaver";
import Leaderboard from "../components/Leaderboard";
import { Button, Form, FormField, Input, Message, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import { saveVenueConfig } from "../redux/actions/table";
const { homeScreen: homeScreenStrings } = strings;

const styles = {
  venueLogo: {
    width: "10%",
    height: "10%",
  },
  modalInput: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "10px",
  },
  inputLabel: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "20%",
  },
  input: {
    width: "80%",
  }
}

const HomeScreen = ({ table }) => {
  const [venueName, setVenueName] = useState("");
  const [show, resetTimer] = useScreensaver();
  const [logoUrl, setLogoUrl] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!table.venue || !table.id) {
      setModalOpen(true);
    }

    return database().ref("scorekeeper/restart").on("value", (snapshot) => {
      // refresh the app and without browser cache
      if (snapshot && snapshot.val() === true) {
        window.location.href = window.location.origin + window.location.pathname + window.location.search + (window.location.search ? '&' : '?') + 'reloadTime=' + Date.now().toString() + window.location.hash;
        // set scorekeeper restart to false
        database().ref("scorekeeper/restart").set(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!!table.venue) {
      firestore()
        .collection("venues")
        .doc(table.venue)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setVenueName(doc.data().name);
          } else {
            setVenueName(table.venue);
          }
        });

      const logoRef = `venues/${table.venue}/logo.png`;
      storage()
        .ref(logoRef)
        .getDownloadURL()
        .then((url) => {
          setLogoUrl(url);
        });
    }
  }, [table.venue])

  const saveConfigs = async () => {
    const venueId = document.getElementById("venueId").value;
    const tableNumber = document.getElementById("tableNumber").value;

    const doc = await firestore().collection("tables").doc(`${venueId}_${tableNumber}`).get();
    console.log(doc);
    if (!doc.exists) {
      setError(true);
      return;
    }

    dispatch(saveVenueConfig(venueId, tableNumber));
    setModalOpen(false);
  }

  return (
    <div onClick={resetTimer}>
      {show && table.venue ? (
        <Leaderboard />
      ) : (
        <div className="page">
          <div className="centered">
            <h1>{venueName.toUpperCase()}</h1>
            {logoUrl && <img style={styles.venueLogo} src={logoUrl} />}
            <h2>TABLE {table.id}</h2>
            <LinkButton to="/gamedataform" text={homeScreenStrings.singles} />
            <LinkButton to="/settings" text={homeScreenStrings.settings} />
          </div>
        </div>
      )}
      <Modal
        onOpen={() => {setModalOpen(true)}}
        onClose={() => {setModalOpen(false)}}
        open={modalOpen}
      >
        <ModalHeader>Set Venue Information</ModalHeader>
        <ModalContent>
          <Form error={error}>
            <FormField>
              <label>VENUE ID:</label>
              <input id="venueId" />
            </FormField>
            <FormField>
              <label>TABLE NUMBER:</label>
              <input id="tableNumber" />
            </FormField>

            <Message
              error
              header="Table Not Found..."
              content="Table not found with the given parameters. Please make sure you have entered the right values. For more support, please feel free to contact us through our support line." />

            <Button onClick={saveConfigs} color="green">SAVE</Button>
          </Form>
        </ModalContent>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    game: state.game,
    players: state.players,
    table: state.table,
  };
};
export default connect(mapStateToProps)(HomeScreen);
