import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { incrementPlayer1Race, incrementPlayer2Race } from "../redux/actions/game";
import ScoreButton from "./ScoreButton";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  increment: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

  },
  label: {
    color: "white",
    fontSize: 50,
    margin: "0 0 0 0",
  },
  race: {
    marginLeft: 40,
    marginRight: 40,
  }
};

const RaceIncrement = ({ game, player, incrementRaceVal }) => {

  return (
    <div style={styles.container}>
      <p style={styles.label}>RACE TO</p>
      <div style={styles.increment}>
        <Button
          circular
          size="huge"
          icon="chevron down"
          onClick={() => incrementRaceVal(-1)}
        />
        <p style={{...styles.label, ...styles.race}}>{player.race}</p>
        <Button
          circular
          size="huge"
          icon="chevron up"
          onClick={() => incrementRaceVal(1)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { type } = props;
  let player;
  if (type == "host") {
    player = state.players.player1;
  } else {
    player = state.players.player2;
  }
  return {
    game: state.game,
    player,
    type,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  const { type } = props;
  if (type == "host") {
    return {
      incrementRaceVal: (increment) => {
        dispatch(incrementPlayer1Race(increment))
      }
    }
  } else {
    return {
      incrementRaceVal: (increment) => {
        dispatch(incrementPlayer2Race(increment))
      }
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RaceIncrement);
