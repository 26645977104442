import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

import SettingsInput from '../components/SettingsInput';
import SetupMenu from '../components/menu/SetupMenu';

import { saveConfig } from '../redux/actions/table';

import LinkButton from "../components/LinkButton";

const styles = {
  streamingToggle: {
    display: "inline-block",
    wwidth: "40%",
  },
  saveConfigButton: {
    marginTop: "3em",
  },
  settingsLabel: {
    display: "inline-block",
    textAlign: "left",
    lineHeight: "100%",
    width: "50%",
    fontSize: "5vh",
    color: "#FFF"
  },
  settingsInput: {
    width: "40%",
  },
  toggleButton: {
    width: "7vw",
  },
  saveButton: {
    backgroundColor: "#9D4BAD",
    color: "#FFF",
    textWeight: "bold",
  }
}

const Settings = ({ defaultRace, timeout, dispatch }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const saveConfigs = () => {
    const newDefaultRace = parseInt(document.getElementById("defaultRace").value);
    const newTimeout = parseInt(document.getElementById("inactivityTimeout").value);

    dispatch(saveConfig(newDefaultRace, newTimeout));
    setModalOpen(false);
  }

  return (
    <div>
      <div className="page">
        <h1>SETTINGS</h1>
          <SettingsInput
            id="defaultRace"
            setting="DEFAULT RACE"
            input="number"
            defaultValue={defaultRace} />
          <SettingsInput
            id="inactivityTimeout"
            setting="INACTIVITY TIMER (MIN)"
            input="number"
            defaultValue={timeout} />
        <div style={styles.saveConfigButton} className="saveConfigButton">
          <Modal
            onClose={() => setModalOpen(false)}
            onOpen={() => setModalOpen(true)}
            open={modalOpen}
            trigger={
              <Button
                size="massive">
                SAVE CONFIGS
              </Button>
            }>
            <Modal.Header>Save Configs</Modal.Header>
            <Modal.Actions>
              <LinkButton to="/settings" text="CANCEL" onClick={() => setModalOpen(false)}></LinkButton>
              <LinkButton to="/" style={styles.saveButton} text="SAVE" positive onClick={saveConfigs}></LinkButton>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
      <SetupMenu />
    </div>
  );
};

const mapStateToProps =  (state) => {
  return {
    defaultRace: state.players.defaultRace,
    timeout: state.table.timeout,
  };
}
export default connect(mapStateToProps)(Settings);
