import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Icon, Input, Modal } from "semantic-ui-react";

import { watchMatch, startMatch, watchTable } from "../redux/actions/game";
import SetupMenu from "../components/menu/SetupMenu";
import LinkButton from "../components/LinkButton";

import PlayerInput from "../components/PlayerInput";
import useScreensaver from "../hooks/useScreensaver";
import { saveDescription } from "../redux/actions/history";
import Screensaver from "../components/Screensaver";

const styles = {
  input: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5vh",
  },
  descriptionInput: {
    padding: "5px",
  }
};

const GameDataForm = ({ game, table, dispatch }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [show, resetTimer] = useScreensaver();
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const unsub = dispatch(watchTable());
    return () => {
      unsub();
    };
  }, [table.venue, table.id]);

  useEffect(() => {
    let unsub = undefined;
    if (game.id) {
      unsub = dispatch(watchMatch());
    }
    return () => {
      if (unsub) unsub();
    };
  }, [game.id]);

  useEffect(() => {
    if (!game.gameOver) {
      setRedirect(true);
    }
  }, [game.gameOver]);

  const start = (isStreaming) => {
    if (started) {
      return;
    }

    const description = document.getElementById("streamDescription")
      ? document.getElementById("streamDescription").value
      : "";
    dispatch(saveDescription(description));

    const p1Input = document.getElementById("p1Input");
    const p2Input = document.getElementById("p2Input");

    dispatch(
      startMatch(
        p1Input && p1Input.value.length > 0 ? p1Input.value.replace("/", "\\") : "PLAYER ONE",
        p2Input && p2Input.value.length > 0 ? p2Input.value.replace("/", "\\") : "PLAYER TWO",
        isStreaming,
        document.getElementById("streamDescription") &&
          document.getElementById("streamDescription").value
      )
    );
    setStarted(true);
  };

  return (
    <div onClick={resetTimer}>
      {!!redirect && <Redirect push to="/scoreboard" />}
      {show ? (
        <Screensaver />
      ) : (
        <div className="page">
          <h1>SINGLES</h1>
          <div style={styles.input}>
            <PlayerInput type="host" />
            <PlayerInput type="guest" />
          </div>
          <LinkButton
            to="/scoreboard"
            text="NORMAL MATCH"
            onClick={() => start(false)}
          />
          <Modal
            onClose={() => setModalOpen(false)}
            onOpen={() => setModalOpen(true)}
            open={modalOpen}
            trigger={
              <Button
                color="youtube"
                onClick={() => setModalOpen(true)}
                size="massive"
              >
                <Icon name="youtube" /> STREAM TO YOUTUBE
              </Button>
            }
          >
            <Modal.Header>Add Stream Description</Modal.Header>
            <Modal.Content>
              <div id="streamDescriptionContainer">
                <Input
                  id="streamDescription"
                  placeholder="Enter Stream Description"
                  autoComplete="off"
                  style={styles.descriptionInput}
                />
              </div>
              {localStorage.getItem("last_description") && (
                <div style={{ marginTop: "3em" }}>
                  <div>
                    <h3>History </h3>
                    <Button
                      content={localStorage.getItem("last_description")}
                      onClick={() => {
                        document.getElementById("streamDescription").value =
                          localStorage.getItem("last_description");
                      }}
                    ></Button>
                  </div>
                </div>
              )}
              <p>Description will appear on screen and description of video. Any videos with text against our policies will be removed.</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="CANCEL"
                color="red"
                onClick={() => setModalOpen(false)}
              />
              <Button content="STREAM" onClick={() => start(true)} positive />
            </Modal.Actions>
          </Modal>
        </div>
      )}
      <SetupMenu />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.players,
    game: state.game,
    table: state.table,
  };
};
export default connect(mapStateToProps)(GameDataForm);
