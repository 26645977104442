import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Race from './Race';
import ScoreButton from './ScoreButton';

const PlayerScore = ({game, player, side, dispatch}) => {
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (highlight) {
      const timer = setTimeout(() => setHighlight(false), 15000);
      return () => clearTimeout(timer);
    }
  }, [highlight]);

  const handleClick = () => {
    console.log("PlayerScore handleClick");
    setHighlight(true);
  };

  return (
    <div
      className={`split ${side}`}
      onClick={handleClick}
      onTouchStart={handleClick}
    >
      <div className="centeredScore" style={{ color: highlight ? "#9d4bad" : "#FFFFFF" }}>
        <p>{player.score}</p>
      </div>
      <div className="playerName" style={{ color: highlight ? "#9d4bad" : "#FFFFFF" }}>
        <p>{player.name}</p>
      </div>
      <ScoreButton
        side={side}
        operation="add"
      />
      <ScoreButton
        side={side}
        operation="minus"
      />
      {!game.winner && <Race race={player.race}/>}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { side } = ownProps;
  let player;
  if ( side === "p1" ) {
    player = state.players.player1;
  } else {
    player = state.players.player2;
  }
  return {
    game: state.game,
    player,
    side
  };
}
export default connect(mapStateToProps)(PlayerScore);
